import Modal from 'flarum/components/Modal';
import app from 'flarum/app';
import Button from 'flarum/components/Button';

export class editNameModal extends Modal {
    constructor(user){
        super();
        this.user = user;
        this.fullname = user.attrs.user.attribute('fullname');    
        this.dirty = false;
        this.loading = false;
    }

    title(){
        return app.translator.trans('customs-fullname.forum.settings.link');
    }

    content(){
        return m('.Modal-body', [
            m('.Form', [
                m('.Form-group',[
                    m('label', app.translator.trans('customs-fullname.forum.settings.link')),
                    m('input[type=text].FormControl', {
                        placeholder: app.translator.trans('customs-fullname.forum.settings.link'),
                        value: this.fullname,
                        id: 'fullname',
                        onchange: e => {
                            this.fullname = e.target.value;
                            this.dirty = true;
                        },
                    }),
                ]),
                
                m('.Form-group',[
                    Button.component({
                        disabled: !this.dirty,
                        loading: this.loading,
                        className: 'Button Button--primary',
                        type: 'submit',

                    }, app.translator.trans('customs-fullname.forum.settings.submit')),
                ]),
            ]),
        ]);
    }

    onsubmit(e){
        e.preventDefault();
        this.loading = true;

        if (this.fullname.length == 0) {
            this.loading = false;
            m.redraw();
            document.getElementById("fullname").focus();
        }

        this.attrs.user.save({
            fullname: this.fullname,
        }).then(() => {
            this.loading = false;
            this.dirty = false;
            m.redraw();
            
            location.reload();
        }).catch(err => {
            this.loading = false;
            m.redraw();
            throw err;
        })
    }
}