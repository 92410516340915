import {extend} from 'flarum/extend';
import app from 'flarum/app';
import UserCard from 'flarum/components/UserCard';
import Button from 'flarum/components/Button';
import UserControls from 'flarum/utils/UserControls';
import { editNameModal } from './components/editNameModal';
import Model from "flarum/Model";
import User from "flarum/models/User";
import Stream from "flarum/utils/Stream";
import SignUpModal from'flarum/components/SignUpModal';
import PostUser from 'flarum/components/PostUser';

app.initializers.add('customs/user-fname-and-lname', () => {
  User.prototype.fullname = Model.attribute("fullname");

  SignUpModal.prototype.submitData = function() {
    const data = {
      username: this.username(),
      email: this.email(),
      fullname: this.fullname(),
    };

    if (this.attrs.token) {
      data.token = this.attrs.token;
    } else {
      data.password = this.password();
    }

    return data;
  }

  /*extend(UserControls, 'userControls', (items, user) => {
    console.log(user);
    items.add('customs-fullname', Button.component({
      icon: 'far fa-address-book',
      title: app.translator.trans('customs-fullname.forum.settings.link'),
      onclick: (e) => {
        app.modal.show(editNameModal, { user });
      },
    }, app.translator.trans('customs-fullname.forum.settings.link')));
  });

  extend(UserCard.prototype, 'oninit', function () {
    this.fullname = Stream(this.attrs.user.fullname());
  });

  extend(UserCard.prototype, 'infoItems', function (items) {
    items.add("fullname", <p>{ this.fullname() }</p>);
  });*/

  extend(SignUpModal.prototype, 'oninit', function () {
    this.fullname = Stream(this.attrs.fullname || '');
  });

  extend(SignUpModal.prototype, 'fields', function (items) {
    items.add(
      'fullname',
      <div className="Form-group">
        <input
          className="FormControl"
          name="fullname"
          id="fullname"
          type="text"
          bidi={this.fullname}
          placeholder="ชื่อและนามสกุล"
          disabled={this.loading || this.isProvided('fullname')}
        />
      </div>,
      25
    );
  });
});
